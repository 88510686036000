<template>
  <Navbar />
  <div class="print-main">
    <header class="print-header" ref="printHeader">
      <div class="print-header__info">
        <h2 class="print-header__info__text">
          All Assemblages are printed on fine Hahnemühle Ultra Smooth 400g paper
          and come in a black or white aluminum frame.
        </h2>
      </div>
      <div class="print-header__prices">
        <article
          class="print-header__prices__block"
          v-for="item in variants"
          :key="item.size"
        >
          <h4 class="print-header__prices__block__format">{{ item.format }}</h4>
          <p>Ξ {{ item.price }}</p>
        </article>
      </div>
      <div class="print-header__btn-holder">
        <button
          class="print-btn print-btn--order"
          @click="prepareOrder()"
          :disabled="totalItems === 0"
        >
          Order {{ totalItems > 0 ? `(${totalItems})` : "" }}
        </button>
      </div>

      <div class="print-btn-ui print-btn--show-header" @click="showInfos">
        show infos
      </div>
      <div class="print-btn-ui print-btn--close-header" @click="hideInfos">
        hide
      </div>
    </header>
    <div
      class="print-loading"
      :class="{ 'print-loading--active': isLoadingUserTokens }"
    >
      Loading…
    </div>
    <div class="print-alert" v-if="getUserTokens.length === 0">
      You need to hold an Assemblage token in your wallet to order a print.
      <router-link
        class="button button--secondary"
        style="text-decoration: none"
        to="/mint"
        >Mint</router-link
      >
    </div>
    <main
      v-else
      class="print-holder"
      :style="`--print-wall-token-amount:${getUserTokens.length}`"
    >
      <article
        class="print-block"
        v-for="item in getUserTokens"
        v-bind:key="item.idx"
      >
        <div
          class="print-block__image-holder"
          :class="{
            'print-block__image-holder--creme':
              cart.colors[item.idx] === 'white',
          }"
        >
          <vue-load-image class="image-frame">
            <template v-slot:image>
              <img
                :src="item.metadata.image"
                :alt="item.metadata.name"
                class="print-block__image"
              />
            </template>
            <template v-slot:preloader>
              <div class="print-block__image-loading">
                <div>loading...</div>
              </div>
            </template>
            <template v-slot:error>
              <div class="print-block__image-loading">
                <div>Your assemblage takes some time to generate</div>
              </div>
            </template>
          </vue-load-image>
        </div>
        <div class="form-control print-form">
          <div>
            <label
              class="cursor-pointer label print-form__label print-form__label--color"
            >
              <span class="label-text print-form__label__text"
                >Frame Color</span
              >
              <input
                type="checkbox"
                v-model="cart.colors[item.idx]"
                value="black"
                checked
                true-value="black"
                false-value="white"
                @click="initColor(item.idx, '')"
                class="toggle toggle-md print-form__toggle--color"
              />
            </label>
          </div>
          <div>
            <label
              v-for="variant in variants"
              :key="variant.format"
              class="cursor-pointer label print-form__label"
            >
              <input
                type="checkbox"
                v-model="cart.sizes[item.idx]"
                :value="variant.format"
                @click="initSize(item.idx, variant.format)"
                class="toggle toggle-md print-form__toggle"
              />
              <span class="label-text print-form__label__text">{{
                variant.format
              }}</span>
            </label>
          </div>
        </div>
      </article>
    </main>

    <Modal ref="checkout" use-light use-scroll>
      <template v-slot:body>
        <div class="modal__data">
          <div>
            <form class="modal__form" @submit.prevent="processOrder">
              <label for="u_name" class="modal__form__label">Name</label>
              <input
                v-model="recepient.name"
                type="text"
                id="u_name"
                class="modal__form__input_field"
              />
              <label for="u_adress" class="modal__form__label"
                >Shipping address</label
              >
              <textarea
                v-model="recepient.address"
                name="u_adress"
                cols="30"
                rows="4"
                id="u_adress"
                class="modal__form__input_field"
              ></textarea>
              <label for="u_email" class="modal__form__label"
                >Email address</label
              >
              <input
                v-model="recepient.email"
                type="email"
                id="u_email"
                class="modal__form__input_field"
              />
              <label for="u_phone" class="modal__form__label"
                >Phone number (optional)</label
              >
              <input
                v-model="recepient.phone"
                type="text"
                id="u_phone"
                class="modal__form__input_field"
              />
              <label for="u_notes" class="modal__form__label"
                >Order notes (optional)</label
              >
              <textarea
                v-model="recepient.notes"
                name="u_notes"
                cols="30"
                rows="4"
                id="u_notes"
                class="modal__form__input_field"
              ></textarea>
              <div class="modal__form__buttons">
                <div
                  @click="$refs.checkout.closeModal()"
                  class="button button--secondary"
                  id="close-modal"
                  style="color: #000"
                >
                  cancel
                </div>
                <button
                  :disabled="!isOrderComplete"
                  class="button button--primary"
                >
                  Order for Ξ {{ totalPrice }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </template>
    </Modal>
    <Modal ref="orderSuccess" use-light>
      <template v-slot:body>
        <p class="modal__slim-text">
          Your order has been received. It will be processed as soon as your ETH
          transaction gets confirmed. You will receive a confirmation Email in
          the next 24 hours.
        </p>
        <div class="modal__form__buttons">
          <button
            @click="$refs.orderSuccess.closeModal()"
            class="button button--primary"
          >
            Okay
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue"
import { mapGetters } from "vuex"
import Modal from "../components/Modal.vue"
import VueLoadImage from "vue-load-image"

export default {
  components: {
    Navbar,
    Modal,
    "vue-load-image": VueLoadImage,
  },

  data() {
    return {
      variants: [
        { price: 0.2, format: "45×30cm" },
        { price: 0.25, format: "60×40cm" },
        { price: 0.3, format: "90×60cm" },
      ],
      recepient: {
        name: "",
        address: "",
        email: "",
      },
      initialCart: {
        sizes: {},
        colors: {},
      },
      cart: {
        sizes: {},
        colors: {},
      },
    }
  },
  computed: {
    ...mapGetters("tokens", ["getUserTokens", "isLoadingUserTokens"]),
    totalItems: function () {
      return Object.values(this.cart.sizes)
        .map((row) => row.length)
        .reduce((a, b) => a + b, 0)
    },
    totalPrice: function () {
      return Object.values(this.cart.sizes)
        .map((row) =>
          row
            .map(
              (el) =>
                this.variants.filter((variant) => variant.format === el)[0]
                  .price
            )
            .reduce((a, b) => a + b, 0)
        )
        .reduce((a, b) => a + b, 0)
    },
    order: function () {
      return {
        cart: this.cart,
        recepient: this.recepient,
        address: this.$store.state.accounts.activeAccount,
        network: this.$store.state.accounts.chainName,
        totalPrice: this.totalPrice,
      }
    },
    isOrderComplete: function () {
      return !Object.entries(this.recepient)
        .map((el) => {
          const [k, v] = el
          if (k === "email") {
            return this.validEmail(v)
          } else {
            return v !== ""
          }
        })
        .includes(false)
    },
  },
  watch: {
    "$store.state.contracts.contract": {
      handler: function () {
        if (this.$store.state.contracts.contract) {
          this.$store.dispatch("tokens/fetchUserTokens")
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$store.state.contracts.contract) {
      this.$store.dispatch("tokens/fetchUserTokens")
    }
  },
  methods: {
    showInfos() {
      this.$refs.printHeader.classList.add("print-header--open")
    },
    hideInfos() {
      this.$refs.printHeader.classList.remove("print-header--open")
    },
    initSize(item, size) {
      if (!this.cart.sizes[item]) this.cart.sizes[item] = [size]
    },
    initColor(item) {
      if (this.cart.colors[item] === undefined) this.cart.colors[item] = "white"
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    prepareOrder() {
      if (this.totalItems > 0) this.$refs.checkout.openModal()
    },
    async processOrder() {
      if (!this.isOrderComplete) return
      let tx
      try {
        tx = await this.$store.dispatch("contracts/order", this.order)
      } catch (error) {
        if (error.code === 4001) {
          error.message = "User denied transaction"
          this.$toast.error(error.message)
        }
      }
      if (tx) {
        this.$refs.checkout.closeModal()
        this.cart = { ...this.initialCart }
        setTimeout(() => {
          this.$refs.orderSuccess.openModal()
        }, 1000)
        tx.wait().then(() => {
          this.$toast.success("Order successful")
        })
      }
    },
  },
}
</script>
<style scoped src="../styles/print.css"></style>
<style src="../styles/toggle.css"></style>
