import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Toaster from "@meforma/vue-toaster"
import VueMatomo from "vue-matomo"

import "./styles/base.css"
import "./styles/additional.css"

createApp(App)
  .use(store)
  .use(router)
  .use(VueMatomo, {
    host: "https://palacz.at/analytics/",
    siteId: 4,
    router: router,
  })
  .use(Toaster, {
    position: "top",
    duration: 3000,
    maxToasts: 3,
  })
  .mount("#app")
