<template>
  <div class="dada">
    <button
      class="main-button"
      title="I am a dadaist"
      v-if="!isUserConnected"
      @click="connectWeb3Modal"
    >
      I am a dadaist
    </button>
    <button
      class="main-button main-button--error"
      title="wrong chain"
      disabled
      v-else-if="isWrongChain"
    >
      wrong network
    </button>
    <button
      class="main-button main-button--error"
      title="This wallet is not dada"
      v-else-if="!isMintEnabled && isUserConnected"
    >
      this wallet is not dada
    </button>
    <button
      class="main-button"
      title="mint now"
      v-else-if="isMintEnabled && isUserConnected"
      @click="$router.push('/mint')"
    >
      mint now
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: "Dada",
  computed: {
    ...mapGetters("accounts", ["isUserConnected", "getWeb3Modal"]),
    ...mapGetters("contracts", ["isMintEnabled", "isWrongChain"]),
  },
  methods: {
    ...mapActions("accounts", ["connectWeb3Modal", "disconnectWeb3Modal"]),
  },
}
</script>
<style scoped>
.dada {
  font-size: 1.5em;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.main-button {
  color: white !important;
}
</style>
<style scoped src="../styles/home.css"></style>
