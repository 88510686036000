import { createStore } from "vuex"
import accounts from "./modules/accounts.js"
import api from "./modules/api.js"
import contracts from "./modules/contracts.js"
import tokens from "./modules/tokens.js"

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    accounts,
    api,
    contracts,
    tokens,
  },
})
