<template>
  <Navbar />
  <div class="mint__main">
    <h1 class="main-headline">Select a token<br />to mint an Assemblage</h1>
    <div v-if="getChainName != 'Localhost' && isUserConnected">
      <TokenSelector />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import TokenSelector from "@/components/TokenSelector.vue"
import Navbar from "../components/Navbar.vue"

export default {
  name: "Home",
  components: {
    TokenSelector,
    Navbar,
  },
  computed: {
    ...mapGetters("accounts", [
      "getActiveAccount",
      "getChainName",
      "isUserConnected",
      "getActiveBalanceEth",
    ]),
    ...mapGetters("contracts", [
      "getContractAddress",
      "getContractDetails",
      "getMintPrice",
      "isLoading",
    ]),
  },
  watch: {
    "$store.state.accounts.isConnected": {
      handler: function () {
        if (this.$store.state.accounts.isConnected) {
          this.$store.dispatch("tokens/fetchTokens")
        }
      },
      immediate: true, // provides initial (not changed yet) state
    },
  },

  methods: {
    ...mapActions("accounts", ["connectWeb3Modal"]),
  },
}
</script>
<style src="../styles/mint.css"></style>
