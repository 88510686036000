import Web3Modal from "web3modal"
import { ethers } from "ethers"
import WalletConnectProvider from "@walletconnect/web3-provider"
import router from "../../router"

const state = {
  activeAccount: null,
  activeBalance: 0,
  chainId: null,
  chainName: null,
  providerEthers: null, // this is "provider" for Ethers.js
  isConnected: false,
  providerW3m: null, // this is "provider" from Web3Modal
  web3Modal: null,
}

const getters = {
  getActiveAccount(state) {
    return state.activeAccount
  },
  getActiveBalanceWei(state) {
    return state.activeBalance
  },
  getActiveBalanceEth(state) {
    return ethers.utils.formatEther(state.activeBalance)
  },
  getChainId(state) {
    return state.chainId
  },
  getChainName(state) {
    return state.chainName
  },
  getProviderEthers(state) {
    return state.providerEthers
  },
  getWeb3Modal(state) {
    return state.web3Modal
  },
  isUserConnected(state) {
    return state.isConnected
  },
}

const actions = {
  async initWeb3Modal({ commit, dispatch }) {
    const providerOptions = {
      // MetaMask is enabled by default
      // Find other providers here: https://github.com/Web3Modal/web3modal/tree/master/docs/providers
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: process.env.VUE_APP_INFURA_ID, // required
        },
      },
    }

    const w3mObject = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions, // required
    })

    // This will get deprecated soon. Setting it to false removes a warning from the console.
    //window.ethereum.autoRefreshOnNetworkChange = false

    // if the user is flagged as already connected, automatically connect back to Web3Modal

    if (localStorage.getItem("isConnected") === "true") {
      console.log("isConnected")

      let providerW3m
      try {
        providerW3m = await w3mObject.connect()
      } catch (error) {
        console.error("Unable to connect:", error)
        commit("setIsConnected", false)
      }
      if (providerW3m) {
        commit("setIsConnected", true)
        commit(
          "setActiveAccount",
          providerW3m.selectedAddress || providerW3m.accounts[0]
        )
        commit("setChainData", providerW3m.chainId)
        commit("setEthersProvider", providerW3m)
        actions.fetchActiveBalance({ commit, dispatch })
      }
    }
    commit("setWeb3ModalInstance", w3mObject)
  },

  async connectWeb3Modal({ commit, dispatch }) {
    let providerW3m = await state.web3Modal.connect()
    commit("setIsConnected", true)
    commit(
      "setActiveAccount",
      providerW3m.selectedAddress || providerW3m.accounts[0]
    )
    commit("setChainData", providerW3m.chainId)
    commit("setEthersProvider", providerW3m)
    actions.fetchActiveBalance({ commit, dispatch })
  },

  async disconnectWeb3Modal({ commit }) {
    commit("disconnectWallet")
    commit("setIsConnected", false)
  },

  async ethereumListener({ commit, dispatch }) {
    if (!window.ethereum) return
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length === 0) {
        dispatch("disconnectWeb3Modal")
        return
      }
      if (state.isConnected) {
        commit("setActiveAccount", accounts[0])
        commit("setEthersProvider", state.providerW3m)
        dispatch("fetchActiveBalance")
        dispatch("tokens/fetchTokens", null, { root: true })
      }
    })

    window.ethereum.on("chainChanged", (chainId) => {
      commit("setChainData", chainId)
      commit("setEthersProvider", state.providerW3m)
      dispatch("fetchActiveBalance")
      dispatch("contracts/fetchContractDetails", null, { root: true })
      dispatch("tokens/fetchTokens", null, { root: true })
    })
  },

  async fetchActiveBalance({ commit, dispatch }) {
    let balance = await state.providerEthers.getBalance(state.activeAccount)
    commit("setActiveBalance", balance)
    dispatch("contracts/fetchContractDetails", null, { root: true })
  },
}

const mutations = {
  async disconnectWallet(state) {
    state.activeAccount = null
    state.activeBalance = 0
    state.activeTokens = []
    state.providerEthers = null
    if (state.providerW3m.close && state.providerW3m !== null) {
      await state.providerW3m.close()
    }
    state.providerW3m = null
    await state.web3Modal.clearCachedProvider()

    router.push("/")
  },

  setActiveAccount(state, selectedAddress) {
    state.activeAccount = selectedAddress
  },

  setActiveBalance(state, balance) {
    state.activeBalance = balance
  },

  setChainData(state, chainId) {
    if (chainId === 1) chainId = "0x1"
    state.chainId = chainId

    switch (chainId) {
      case "0x1":
        state.chainName = "Mainnet"
        break
      case "0x2a":
        state.chainName = "Kovan"
        break
      case "0x3":
        state.chainName = "Ropsten"
        break
      case "0x4":
        state.chainName = "Rinkeby"
        break
      case "0x5":
        state.chainName = "Goerli"
        break
      case "0x539": // 1337 (often used on localhost)
      case "0x1691": // 5777 (default in Ganache)
      default:
        state.chainName = "Localhost"
        break
    }
  },

  async setEthersProvider(state, providerW3m) {
    state.providerW3m = providerW3m
    state.providerEthers = new ethers.providers.Web3Provider(providerW3m)
  },

  setIsConnected(state, isConnected) {
    state.isConnected = isConnected
    // add to persistent storage so that the user can be logged back in when revisiting website
    localStorage.setItem("isConnected", isConnected)
  },

  setWeb3ModalInstance(state, w3mObject) {
    state.web3Modal = w3mObject
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
