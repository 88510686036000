import ky from "ky"
import { loadToken } from "./token-loader.js"

const apiKey = process.env.VUE_APP_MORALIS_API_KEY

const state = {
  tokenLoadedIdx: 0,
  allTokens: [],
  activeTokens: [],
  loadedTokens: [],
  userTokens: [],
  loadingUserTokens: false,
}

const getters = {
  getUserTokens(state) {
    return state.userTokens
  },
  getAllTokens(state) {
    return state.allTokens
  },
  getActiveTokens() {
    return state.activeTokens
  },
  getLoadedTokens(state) {
    return state.loadedTokens
  },
  canLoadMore(state) {
    return state.tokenLoadedIdx < state.activeTokens.length
  },
  isLoadingUserTokens(state) {
    return state.loadingUserTokens
  },
}

const actions = {
  queryTokens({ state, commit, rootState }, query) {
    if (query === "") {
      commit("setActiveTokens", state.allTokens)
      actions.resetLoadedTokens({ state, commit, rootState })
    } else {
      query = query.toLowerCase()
      const fields = ["name", "token_id", "symbol", "description"]
      const results = state.allTokens.filter((el) => {
        return fields
          .map((field) => {
            const contentMetadata = el.metadata && el.metadata[field]
            if (
              contentMetadata &&
              String(contentMetadata).toLowerCase().includes(query)
            )
              return true
            const content = el[field]
            return content && String(content).toLowerCase().includes(query)
          })
          .includes(true)
      })
      commit("setActiveTokens", results)
      actions.resetLoadedTokens({ state, commit, rootState })
    }
    return state.activeTokens.length
  },

  resetLoadedTokens({ state, commit, rootState }) {
    state.tokenLoadedIdx = 0
    commit("setLoadedTokens", [])
    actions.loadMoreTokens({ state, commit, rootState })
  },

  async loadMoreTokens({ state, rootState, commit }) {
    const load = () => {
      if (state.tokenLoadedIdx < state.activeTokens.length) {
        let token = state.activeTokens[state.tokenLoadedIdx]
        state.tokenLoadedIdx++
        loadToken(token, rootState)
          .then((loadedToken) => {
            commit("addToLoadedTokens", loadedToken)
          })
          .catch((error) => {
            token.failed = true
            load() // another one
            console.error(error)
          })
      }
    }
    for (let index = 0; index < 32; index++) {
      load()
    }
  },

  async fetchTokens({ commit, state, rootState }) {
    if (rootState.accounts.chainName != "Localhost") {
      commit("setLoadedTokens", [])
      commit("setActiveTokens", [])
      commit("setAllTokens", [])
      let cursor = ""
      let tokens = null
      let offset = 0
      const options = {
        headers: {
          "X-API-Key": apiKey,
        },
      }
      const chain = rootState.accounts.chainId === 4 ? "rinkeby" : "eth"
      // const address = "0x82B1F29C5608238DF2618F996827933c0d844079" // Tschuuuly
      const address = rootState.accounts.activeAccount
      do {
        const params = new URLSearchParams({
          chain,
          format: "decimal",
          limit: 100,
          cursor,
        }).toString()
        tokens = await ky(
          `https://deep-index.moralis.io/api/v2/${address}/nft?${params}`,
          options
        ).json()
        const list = tokens.result.map((el) =>
          el.metadata !== null
            ? { ...el, metadata: JSON.parse(el.metadata) }
            : el
        )
        console.debug(list)
        list.forEach((item, idx) => (item.idx = offset + idx))
        list.sort((a, b) => b.block_number - a.block_number)
        commit("appendAllTokens", list)
        if (offset === 0) {
          actions.loadMoreTokens({ state, commit, rootState })
        }
        offset += tokens.page_size
        cursor = tokens.cursor
      } while (cursor != "" && cursor != null)
    }
  },

  async fetchUserTokens({ commit, rootState }) {
    commit("setLoadingUserTokens", true)
    const contract = rootState.contracts.contract
    if (contract) {
      const numTokens = await contract.balanceOf(
        rootState.accounts.activeAccount
      )
      let tokens = []
      for (let index = 0; index < numTokens.toNumber(); index++) {
        const tokenId = await contract.tokenOfOwnerByIndex(
          rootState.accounts.activeAccount,
          index
        )
        const tokenURI = await contract.tokenURI(tokenId)
        let metadata
        try {
          metadata = await ky(tokenURI).json()
        } catch (error) {
          metadata = { image: "https://invalid-img-url.foo" }
        }
        tokens.push({ idx: tokenId, metadata })
      }
      commit("setUserTokens", tokens)
    }
    commit("setLoadingUserTokens", false)
  },
}

const mutations = {
  async disconnectWallet(state) {
    state.allTokens = []
    state.activeTokens = []
    state.loadedTokens = []
    state.userTokens = []
    state.tokenLoadedIdx = 0
  },

  setUserTokens(state, tokens) {
    state.userTokens = tokens
  },

  setActiveTokens(state, tokens) {
    state.activeTokens = tokens
  },

  setAllTokens(state, tokens) {
    state.allTokens = tokens
  },

  setLoadedTokens(state, tokens) {
    state.loadedTokens = tokens
    if (tokens.length === 0) state.tokenLoadedIdx = 0
  },

  setLoadingUserTokens(state, newState) {
    state.loadingUserTokens = newState
  },

  addToLoadedTokens(state, token) {
    state.loadedTokens.push(token)
  },

  appendAllTokens(state, tokens) {
    state.allTokens.push(...tokens)
    state.activeTokens.push(...tokens)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
