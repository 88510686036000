<template>
  <!-- <Navbar /> -->
  <img
    :src="`https://api.assemblage.ee/${$route.params.chainId}/${$route.params.id}/image_comparison.png`"
    alt=""
  />
</template>

<script>
export default {}
</script>

<style scoped>
img {
  margin-top: 3em;
  width: 100%;
}
</style>
