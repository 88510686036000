import ky from "ky"

const api = process.env.VUE_APP_ASSEMBLAGE_STATUS

const state = {
  enabled: false,
  buttonText: "loading...",
  count: 0,
}

const getters = {
  isEnabled(state) {
    return state.enabled
  },
  getButtonText(state) {
    return state.buttonText
  },
  getCount(state) {
    return state.count
  },
}

const actions = {
  fetchInfo({ commit }) {
    ky(api + "?" + Date.now().toString())
      .json()
      .then((info) => {
        commit("setInfo", info)
      })
      .catch((error) => {
        console.error("Could not get status.json from API", error)
      })
  },
}

const mutations = {
  setInfo(state, info) {
    state.enabled = info.enabled
    state.buttonText = info.buttonText
    state.count = info.count
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
