<template>
  <div class="sticky-header">
    <form
      @submit.prevent="searchQuery"
      class="search-form"
      autocomplete="off"
      action=""
    >
      <input
        type="text"
        class="search-form__search-field"
        id="search-token"
        v-model="query"
        spellcheck="false"
        placeholder="…"
      />
      <button
        @click.prevent="clearSearch"
        type="reset"
        class="button button--clear"
      >
        <span>clear</span>
      </button>
      <button type="submit" class="button button--primary">search</button>
    </form>
  </div>

  <div class="grid-holder">
    <section class="grid-container" v-if="getLoadedTokens.length > 0">
      <vue-load-image
        class="grid-container__token"
        v-for="item in getLoadedTokens"
        v-bind:key="item.idx"
        @click="prepareMint(item)"
      >
        <template v-slot:image>
          <img class="token__image" :src="item.final_image" />
        </template>
        <template v-slot:preloader>
          <div class="token__image-loading">loading...</div>
        </template>
        <template v-slot:error>
          <div class="token__image-error">
            <strong>{{ item.name }} / #{{ item.token_id }}</strong
            ><br />could not be loaded, please refresh metadata on OpenSea and
            retry!
          </div>
        </template>
      </vue-load-image>
    </section>
  </div>

  <div class="load-more">
    <button
      class="button button--secondary"
      v-if="canLoadMore"
      @click="loadMoreTokens()"
    >
      load more
    </button>
  </div>

  <Modal ref="mintModal">
    <template v-slot:body v-if="selectedToken">
      <div class="modal__img-holder">
        <img
          class="token__image modal__image"
          :src="selectedToken.final_image"
          :alt="selectedToken.metadata.name"
        />
      </div>
      <div class="modal__minting">
        <div class="modal__text-box">
          <h4 class="modal__text">Generate an Assemblage of</h4>
          <h3 class="modal__text modal__text--name">
            {{ selectedToken.metadata.name }}
          </h3>
          <h3 class="modal__text modal__text--minting-fee">
            Ξ {{ getMintPrice }}
          </h3>
        </div>
        <div>
          <button
            class="button button--secondary"
            @click="$refs.mintModal.closeModal()"
          >
            cancel
          </button>
          <button class="button button--primary" @click="mint(selectedToken)">
            mint
          </button>
        </div>
      </div>
    </template>
  </Modal>
  <Modal ref="confirmationModal">
    <template v-slot:body>
      <div class="modal__slim-text">
        <p>
          Your Assemblage is generated as soon as your transaction gets
          confirmed. It might take a while to be revealed.
          <br /><br />
          Select your favorite piece to be printed.
          <router-link to="/print">Order print</router-link><br /><br />
        </p>
      </div>
      <div class="modal__minting">
        <button
          class="button button--primary button--single"
          @click="$refs.confirmationModal.closeModal()"
        >
          OK
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Modal from "./Modal.vue"
import VueLoadImage from "vue-load-image"

let search_observer

export default {
  data() {
    return {
      loadedTokens: [],
      selectedToken: null,
      query: "",
      searchActive: false,
    }
  },
  components: {
    Modal,
    "vue-load-image": VueLoadImage,
  },
  computed: {
    ...mapGetters("contracts", ["getMintPrice", "isMintEnabled"]),
    ...mapGetters("tokens", ["getLoadedTokens", "canLoadMore", "hasTokens"]),
  },
  mounted() {
    const search_el = document.querySelector(".sticky-header")

    search_observer = new IntersectionObserver(
      ([e]) =>
        e.target.classList.toggle(
          "sticky-header--is-pinned",
          e.intersectionRatio < 1
        ),
      { threshold: [1], rootMargin: ["0px 0px 2000px 0px"] }
    )

    search_observer.observe(search_el)
  },
  unmounted() {
    search_observer = null
  },
  methods: {
    ...mapActions("tokens", ["loadMoreTokens"]),
    async searchQuery() {
      const numResults = await this.$store.dispatch(
        "tokens/queryTokens",
        this.query
      )
      if (numResults === 0) {
        this.$toast.error("No tokens found")
      }
    },
    clearSearch() {
      this.query = ""
      this.searchQuery()
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    },
    async prepareMint(item) {
      if (!this.isMintEnabled) {
        this.$toast.error("Minting is not enabled")
        return
      }
      const alreadyMinted = await this.$store.dispatch(
        "contracts/checkSourceToken",
        item
      )
      if (alreadyMinted) {
        this.$toast.error("This token was already assemblaged")
      } else {
        this.selectedToken = item
        this.$refs.mintModal.openModal()
      }
    },
    async mint(item) {
      let tx
      try {
        tx = await this.$store.dispatch("contracts/mint", item)
      } catch (error) {
        if (error.code === 4001) {
          error.message = "User denied transaction"
          this.$toast.error(error.message)
        }
      }
      if (tx) {
        this.$refs.mintModal.closeModal()
        setTimeout(() => {
          this.$refs.confirmationModal.openModal()
        }, 1000)
        tx.wait().then(() => {
          this.$toast.success("Minting successful")
        })
      }
    },
  },
}
</script>
