import { createRouter, createWebHistory } from "vue-router"
import Home from "../views/Home.vue"
import Mint from "../views/Mint.vue"
import Print from "../views/Print.vue"
import Token from "../views/Token.vue"
import Dada from "../views/Dada.vue"

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/mint",
    name: "mint",
    component: Mint,
  },
  {
    path: "/print",
    name: "print",
    component: Print,
  },
  {
    path: "/dada",
    name: "dada",
    component: Dada,
  },
  {
    path: "/token/:chainId/:id",
    name: "token",
    component: Token,
  },
  {
    path: "/token/:id",
    name: "tokenMainnet",
    component: Token,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
