import { ethers } from "ethers"
import { toRaw } from "vue"
import ky from "ky"
// import router from "../../router"

import Assemblage from "../../contracts/Assemblage.json"
import addresses from "../../contracts/addresses.json"

const assemblageApi = process.env.VUE_APP_ASSEMBLAGE_API

const state = {
  contractAddress: null,
  contract: null,
  details: {
    mintPrice: ethers.utils.parseUnits("0.08", "ether"),
    name: "",
    symbol: "",
    totalSupply: 0,
    maxSupply: 7921,
    mintEnabled: false,
    wrongChain: false,
  },
}

const getters = {
  getContractAddress(state) {
    return state.contractAddress
  },
  getContractDetails(state) {
    return state.details
  },
  getMintPrice(state) {
    return ethers.utils.formatEther(state.details.mintPrice)
  },
  getMaxSupply(state) {
    return state.details.maxSupply
  },
  getTotalSupply(state) {
    return state.details.totalSupply
  },
  isMintEnabled(state) {
    return state.details.mintEnabled
  },
  isWrongChain(state) {
    return state.wrongChain
  },
}

const actions = {
  async checkSourceToken({ state }, item) {
    const alreadyMinted = await state.contract.sourceTokenMinted(
      item.token_address,
      item.token_id
    )
    return alreadyMinted
  },

  async mint({ state, rootState, dispatch }, item) {
    const tx = await state.contract.mint(item.token_address, item.token_id, {
      gasLimit: 500_000,
      value: state.details.mintPrice,
    })
    console.log(`Transaction hash: ${tx.hash}`)
    ky.post(assemblageApi + "/mint", {
      json: {
        hash: tx.hash,
        token_address: item.token_address,
        token_id: item.token_id,
        account: rootState.accounts.activeAccount,
      },
    }).catch((e) => console.error(e))
    tx.wait().then((receipt) => {
      console.log(`Transaction confirmed in block ${receipt.blockNumber}`)
      console.log(`Gas used: ${receipt.gasUsed.toString()}`)
      dispatch("fetchContractDetails")
    })
    return tx
  },

  async order({ state }, order) {
    const tx = await state.contract.orderPrints({
      gasLimit: 500_000,
      value: ethers.utils.parseUnits(order.totalPrice.toFixed(4), "ether"),
    })
    console.log(`Transaction hash: ${tx.hash}`)
    ky.post(assemblageApi + "/order", {
      json: {
        hash: tx.hash,
        order,
      },
    }).catch((e) => console.error(e))
    tx.wait().then((receipt) => {
      console.log(`Transaction confirmed in block ${receipt.blockNumber}`)
      console.log(`Gas used: ${receipt.gasUsed.toString()}`)
    })
    return tx
  },

  async fetchContractDetails({ commit, rootState }) {
    let chainIdDec = parseInt(rootState.accounts.chainId)
    if (chainIdDec === 1337) chainIdDec = 31337
    let contractAddress = addresses.Assemblage[chainIdDec]
    if (contractAddress !== undefined) {
      commit("setWrongChain", false)
      commit("setContractAddress", contractAddress)
      const provider = toRaw(rootState.accounts.providerEthers)
      const signer = provider.getSigner()
      let contract = new ethers.Contract(
        contractAddress,
        Assemblage.abi,
        signer
      )
      commit("setContract", contract)

      let mintPrice = await contract.mintPrice()
      let name = await contract.name()
      let symbol = await contract.symbol()
      let totalSupply = await contract.totalSupply()
      let maxSupply = await contract.maxSupply()
      let mintEnabled = await contract.mintEnabled()
      commit("setDetails", {
        mintPrice,
        name,
        symbol,
        totalSupply,
        maxSupply,
        mintEnabled,
      })
      if (!mintEnabled) {
        // router.push("/")
      }
    } else {
      // router.push("/")
      commit("setWrongChain", true)
    }
  },
}

const mutations = {
  setContractAddress(state, address) {
    state.contractAddress = address
  },
  setContract(state, contract) {
    state.contract = contract
  },
  setDetails(state, details) {
    state.details = details
  },
  setWrongChain(state, chainStatus) {
    state.wrongChain = chainStatus
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
