<template>
  <nav class="nav">
    <router-link class="nav__item" to="/">Home</router-link>
    <router-link class="nav__item" to="/mint">Mint</router-link>
    <router-link class="nav__item" to="/print">Print</router-link>
    <!-- <a
      class="nav__item"
      href="#"
      v-if="!isUserConnected"
      @click="connectWeb3Modal"
      >Connect your wallet</a
    >
    <a
      class="nav__item"
      href="#"
      v-if="isUserConnected"
      @click="disconnectWeb3Modal"
      >{{ getChainName }} Chain {{ getActiveAccount.substring(0, 7) }}...</a
    > -->
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: "Navbar",
  computed: {
    ...mapGetters("accounts", [
      "getActiveAccount",
      "isUserConnected",
      "getChainName",
    ]),
    showChainAlert() {
      switch (this.getChainName) {
        case "Mainnet":
          return false
        default:
          return true
      }
    },
  },
  methods: {
    ...mapActions("accounts", ["connectWeb3Modal", "disconnectWeb3Modal"]),
  },
}
</script>
