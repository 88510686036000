<template>
  <div class="as-modal" :class="{ 'modal--open': show }">
    <div class="modal__bg" :class="{ 'modal__bg--light': useLight }"></div>
    <div
      class="modal__content"
      :class="{
        'modal__content--light': useLight,
        'modal__content--scroll': useScroll,
      }"
    >
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    useLight: Boolean,
    useScroll: Boolean,
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    closeModal() {
      this.show = false
      document.querySelector("body").classList.remove("overflow-hidden")
    },
    openModal() {
      this.show = true
      document.querySelector("body").classList.add("overflow-hidden")
    },
  },
}
</script>
<style>
.overflow-hidden {
  overflow: hidden;
}
</style>
