<template>
  <main>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </main>
  <PageLoader />
</template>

<script>
import PageLoader from "./components/PageLoader.vue"
export default {
  name: "App",
  components: {
    PageLoader,
  },
  beforeMount() {
    this.$store.dispatch("api/fetchInfo")
    this.$store.dispatch("accounts/initWeb3Modal")
    this.$store.dispatch("accounts/ethereumListener")
  },
  watch: {
    $route: {
      handler(to, from) {
        const html = document.getElementsByTagName("html")[0]
        if (from !== undefined) {
          html.classList.remove("page--" + from.name)
        }
        html.classList.add("page--" + to.name)
      },
      immediate: true,
    },
  },
}
</script>
