import ky from "ky"
import { create as ipfsCreate } from "ipfs"
import makeIpfsFetch from "js-ipfs-fetch"

const ipfsGateway = "https://ipfs.moralis.io:2053/ipfs/"
const badIpfsGateways = [
  "https://gateway.pinata.cloud/ipfs/",
  "https://gateway.ipfs.io/ipns/",
  "https://ipfs.infura.io/ipfs/",
  "https://gateway.ipfs.io/ipfs/",
  "https://ipfs.io/ipfs/",
  "https://gateway.pinata.cloud/ipfs/",
]

export const loadToken = async (token, rootState) => {
  if (token.loaded) {
    return token
  } else if (token.failed) {
    throw Error("Already tried!")
  }
  const chainName = rootState.accounts.chainName
  await getTokenMetadata(token, chainName)
  try {
    await getTokenImage(token)
  } catch (error) {
    console.warn("Failed to load image, trying OpenSea metadata", token)
    await getTokenMetadata(token, chainName, true)
    await getTokenImage(token) // may throw
  }
  return token
}

const getTokenMetadata = async (token, chainName, useOpenSea = false) => {
  if (token.metadata === null || useOpenSea) {
    // TRY OPENSEA
    const openSeaUrl = openSeaAsset(
      chainName,
      token.token_address,
      token.token_id
    )
    let metadata
    try {
      metadata = await ky(openSeaUrl).json()
    } catch (error) {
      if (useOpenSea)
        throw new Error(
          `${token.token_address} / ${token.token_id} not possible with OpenSea`
        )
    }
    if (metadata && metadata.image_url) {
      console.debug(`Found metadata with OpenSea`)
      token.metadata = metadata
      return
    }
  }

  if (token.metadata === null) {
    // TRY SOME OTHER TRICKS
    if (token.token_uri.startsWith("data:")) {
      let [, type, encoding, data] = token.token_uri.match(
        /data:([^;]+);([^,]+),(.+)/
      )
      if (type.contains("json")) {
        if (encoding === "base64") {
          data = Buffer.from(data, "base64").toString()
        }
        token.metadata = JSON.parse(data)
      } else {
        token.metadata = { image_data: token.token_uri }
      }
    } else {
      if (token.token_uri.startsWith("ipfs://")) {
        token.token_uri = token.token_uri.replace(
          /^ipfs:\/\/(ipfs\/)*/,
          ipfsGateway
        )
      }
      try {
        token.metadata = await ky(token.token_uri).json()
      } catch (error) {
        throw Error("Could not fetch Token URI!")
      }
    }
  }
}

const getTokenImage = async (token) => {
  if (!token.metadata) throw new Error("Still no metadata, no chance")
  token.final_image =
    token.metadata.image_preview_url ||
    token.metadata.image ||
    token.metadata.image_url ||
    token.metadata.image_original ||
    token.metadata.image_original_url ||
    token.metadata.image_data

  if (token.final_image.startsWith("ipfs://")) {
    try {
      getIpfs(token.final_image)
    } catch (error) {
      console.error(error)
    }
    token.final_image = token.final_image.replace(
      /^ipfs:\/\/(ipfs\/)*/,
      ipfsGateway
    )
  } else if (token.final_image.startsWith("<svg")) {
    const blob = new Blob([token.final_image], { type: "image/svg+xml" })
    token.final_image = URL.createObjectURL(blob)
  } else if (token.final_image.startsWith("data:text/plain")) {
    throw Error("Can not handle data:text/plain!")
  }

  badIpfsGateways.forEach((badGateway) => {
    token.final_image = token.final_image.replace(badGateway, ipfsGateway)
  })
  // const imageElem = await loadImage(token.final_image)

  // // The following fetch is just to check if the
  // if (token.final_image.startsWith("http")) {
  //   let response,
  //     success = false
  //   try {
  //     response = await fetch(token.final_image, {
  //       timeout: 3000,
  //       headers: {
  //         Range: "bytes=0-16",
  //       },
  //     })
  //     success = true
  //   } catch (error) {
  //     // Don't report as this could be normal
  //   }
  //   if (success) {
  //     const contentType = response.headers.get("Content-Type")
  //     if (!contentType.startsWith("image")) {
  //       throw Error("Image URL is incompatible: " + contentType)
  //     }
  //   }
  // }

  // if nothing fails imageEl will be set
  // token.imageEl = imageElem
}

const loadImage = (src) =>
  new Promise(function (resolve, reject) {
    const image = new Image()
    image.addEventListener("load", () => resolve(image), false)
    image.addEventListener(
      "error",
      () => reject(new Error("Cannot load image")),
      false
    )
    image.src = src
  })
loadImage
const openSeaAsset = (chainName, address, tokenId) => {
  if (chainName === "localhost") return null
  const testnetPrefix =
    chainName === "Mainnet" ? "" : chainName.toLowerCase() + "-"
  const url = `https://${testnetPrefix}api.opensea.io/api/v1/asset/${address}/${tokenId}/`
  return url
}

let ipfsInit, ipfs, fetch
const getIpfs = async (cid) => {
  if (!ipfsInit) {
    ipfsInit = true
    ipfs = await ipfsCreate()
    fetch = await makeIpfsFetch({ ipfs })
    const response = await fetch(cid)
    console.log("fetch start", cid)
    console.log(response)
  }
}
