<template>
  <div id="loading" ref="loading" class="active" v-if="!isloaded">
    <div class="loader">
      <div class="loader_item"></div>
      <div class="loader_item"></div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      isloaded: false,
    }
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        const loading = this.$refs.loading
        loading.classList.add("removed")
        setTimeout(function () {
          loading.classList.remove("active")
          this.isloaded = true
        }, 1000)
      }
    }
  },
}
</script>

<style scoped>
/* loading */

#loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: var(--dark);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 10000;
}

#loading.removed {
  transition: transform 0.5s ease;
  transform: translateY(-100%);
}

.loader {
  position: relative;
  width: 40px;
  height: 40px;
}

.loader_item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: var(--light);

  opacity: 0.4;
  transform: scale(0.2);
}

#loading.active .loader_item {
  animation: loader_anim 3s ease-in-out infinite;
}

#loading.active .loader_item:nth-of-type(2) {
  animation-delay: -1.5s;
}

@keyframes loader_anim {
  0%,
  100% {
    transform: scale(0.2);
    opacity: 0.8;
  }

  30% {
    transform: scale(2, 0.02);
    opacity: 0;
  }
}
</style>
